import request from '@/api/axios';

// 海报列表
export function getPosterList(param) {
  return request({
    url: `/product-manager/brandShop/poster/l_search`,
    method: 'post',
    data: param,
    isNewType: true
  });
}

// 新增海报
export function addPoster(param) {
  return request({
    url: `/product-manager/brandShop/poster/l_add`,
    method: 'post',
    data: param,
    isNewType: true
  });
}

// 修改海报
export function updatePoster(param) {
  return request({
    url: `/product-manager/brandShop/poster/l_update`,
    method: 'post',
    data: param,
    isNewType: true
  });
}

// 海报上架、下架
export function updatePosterStatus(param) {
  return request({
    url: `/product-manager/brandShop/poster/l_status`,
    method: 'post',
    data: param,
    isNewType: true
  });
}

