<template>
  <div>
    <div class="main-container manage">
      <div class="row-panel search-panel">
        <el-input
          v-model.trim="searchParams.skey"
          class="search-item"
          style="width: 260px;"
          placeholder="标题"
          size="mini"
          clearable
          @clear="querartmesList"
          @keyup.enter.native="searchInit"
        />
        <z-button
          type="primary"
          size="medium"
          class="search-item"
          @click="searchInit"
        >
          查询
        </z-button>
        <div class="search-item">
          <span class="search-label">发布日期</span>
          <el-date-picker
            v-model="searchParams.beginAndEndTime"
            size="mini"
            type="daterange"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            @change="fetchartmessList"
          />
        </div>
      </div>
      <div class="row-panel relative">
        <div
          class="release_btn"
          @click="openarticle()"
        >
          <z-button
            type="primary"
            size="medium"
          >
            <i class="iconfont fabuhuodong" />发布海报
          </z-button>
        </div>
        <el-tabs
          v-model="searchParams.sstatus"
          @tab-click="changeTab"
        >
          <el-tab-pane
            v-for="(item,index) in tabStatus"
            :ref="'tab' + item.value"
            :key="index"
            :label="item.text"
            :name="item.value"
          />
        </el-tabs>
        <el-table
          v-loading="loading"
          :data="manageList"
          stripe
          border
          style="width: 100%"
        >
          <el-table-column
            prop="sshow_poster_id"
            label="海报ID"
            min-width="177"
          >
            <template slot-scope="scope">
              {{ scope.row.sshow_poster_id ? scope.row.sshow_poster_id : '-' }}
            </template>
          </el-table-column>
          <el-table-column
            prop="stitle"
            label="海报标题"
            min-width="300"
          >
            <template slot-scope="scope">
              <!-- <copy-text :copy-text="scope.row.stitle" /> -->
              {{ scope.row.stitle ? scope.row.stitle : '-' }}
            </template>
          </el-table-column>
          <el-table-column
            prop="sshow_status"
            label="显示状态"
            min-width="224"
          >
            <template slot-scope="scope">
              <template v-if="scope.row.sshow_status == '0'">即将开始</template>
              <template v-else-if="scope.row.sshow_status == '1'">进行中</template>
              <template v-else-if="scope.row.sshow_status == '2'">已结束</template>
              <template v-else>-</template>
            </template>
          </el-table-column>
          <el-table-column
            prop="sjump_url"
            label="跳转链接"
            min-width="400"
          >
            <template slot-scope="scope">
              {{ scope.row.sjump_url ? scope.row.sjump_url : '-' }}
            </template> 
          </el-table-column>
          <el-table-column
            prop="nclick_num"
            label="点击次数/人数"
            min-width="300"
          >
            <template slot-scope="scope">
              {{ scope.row.nclick_num ? scope.row.nclick_num : 0 }} / {{ scope.row.nclick_people ? scope.row.nclick_people : 0 }}
            </template> 
          </el-table-column>
          <el-table-column
            prop="dpublish_time"
            label="发布时间 "
            min-width="160"
          >
            <template slot-scope="scope">
              {{ scope.row.dpublish_time ? scope.row.dpublish_time : '-' }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            fixed="right"
            label="操作"
            min-width="200"
          >
            <template slot-scope="scope">
              <z-button
                type="text"
                size="small"
                :color="2"
                font-size="small"
                @click="editArt(scope.row)"
              >
                修改状态
              </z-button>
              <z-button
                type="text"
                size="small"
                :color="2"
                font-size="small"
              >
                <span
                  v-if="scope.row.sstatus==0"
                  @click="upAndlow(scope.row,1)"
                >发布</span>
                <span
                  v-if="scope.row.sstatus==1"
                  @click="upAndlow(scope.row,0)"
                >下架</span>
              </z-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          popper-class="paginationComCls"
          style="text-align:center;margin-top:20px"
          layout="sizes, prev, pager, next,total"
          :total="total"
          :page-sizes="[12, 20, 30, 40, 50]"
          :page-size="pageSize"
          :current-page="pageNum"
          @current-change="current_page_change"
          @size-change="handleSizeChange"
        />
      </div>
    </div>
    <editDialog
      v-if="visibleDialog"
      :visible.sync="visibleDialog"
      :current-param="currentParam"
      @refreshList="refreshtable"
    ></editDialog>
    <warm-pop
      :pop="pop.ispop"
      :is-cancle="true"
      :close-img="true"
      :icon="-11"
      :title="pop.title"
      @close="close"
    >
      <z-button
        slot="btn"
        type="primary"
        @click="comfirmxj()"
      >
        确认
      </z-button>
    </warm-pop>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { offart } from '../../api/manage';
import { getPosterList, updatePosterStatus } from "../../api/poster"
import { dealBeginTime, dealEndTime } from '../../../public/js/util';
import copyText from '../../components/common/copyText';
import warmPop from '../../components/common/warmPop';
import editDialog from './editDialog.vue';

export default {
  name: 'posterManage',
  components: {
    copyText,
    warmPop,
    editDialog
  },
  data() {
    return {
      pop: {
        ispop: false,
        title: '',
      },
      manageList: [],
      searchParams: {
        skey: '',
        beginAndEndTime: '',
        sstatus: '1',
      },
      pageNum: 1,
      pageSize: 12,
      total: 0,
      tabStatus: [
        {
          text: '发布中',
          value: '1',
        },
        {
          text: '已下架',
          value: '0',
        },
      ],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          },
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          },
        }],
      },
      loading: false,
      visibleDialog: false,
      currentParam: {},
      artParam: {},
      offparam: {},
    };
  },
  computed: {
    ...mapGetters(['brandInfo']),
    beginTime() {
      return this.searchParams.beginAndEndTime ? dealBeginTime(this.searchParams.beginAndEndTime[0]) : '';
    },
    endTime() {
      return this.searchParams.beginAndEndTime ? dealEndTime(this.searchParams.beginAndEndTime[1]) : '';
    },
  },
  watch: {
    beginTime: {
      handler(newVal, oldVal) {
        console.log('时间', this.searchParams.beginAndEndTime);
      },
    },
    endTime: {
      handler(newVal, oldVal) {
        console.log('时间w', this.searchParams.beginAndEndTime);
      },
    },
  },
  mounted() {
    this.querartmesList();
  },
  methods: {
    comfirmxj() {
      updatePosterStatus(this.offparam).then((res) => {
        const text = this.offparam.sstatus == '1' ? '发布成功' : '下架成功' 
        const {code} = res
        if (code === 0) {
          this.$message.success(text);
          this.close();
          this.querartmesList();
        } else {
          this.$message.success('失败');
        }
      });
    },
    close() {
      this.pop.ispop = false;
      this.offparam = {};
    },
    upAndlow(item, index) {
      if (index === 0) {
        this.pop.title = '确定下架该海报？';
      } else {
        this.pop.title = '确定发布该海报？';
      }
      const param = {
        sstatus: index,
        sposter_id: item.sposter_id,
        nbrandId: this.brandInfo.nbrandId,
      };
      if (param) {
        this.offparam = param;
      }
      this.pop.ispop = true;
    },
    successToEdit() {
      this.visibleDialog = false;
      this.querartmesList();
    },
    querartmesList() {
      this.loading = true;
      const param = {
        nbrand_id: this.brandInfo.nbrandId,
        sstatus: this.searchParams.sstatus,
        skey: this.searchParams.skey,
        dstart_time: this.beginTime,
        dend_time: this.endTime,
        page_size: this.pageSize,
        page_index: this.pageNum
      };
      getPosterList(param).then(res => {
        const {code, data, message} = res
        this.loading = false;
        if(code === 0) {
          this.manageList = data.list;
          this.total = data.pager.total;
        } else {
          this.$message.error(message);
        }
      })
    },
    refreshtable() {
      this.visibleDialog = false;
      this.querartmesList();
    },
    searchInit() {
      this.pageNum = 1;
      this.querartmesList();
    },
    fetchartmessList() {
      this.pageNum = 1;
      this.querartmesList();
    },
    openarticle() {
      this.visibleDialog = true;
      this.currentParam = {};
    },
    editArt(param) {
      this.visibleDialog = true;
      this.currentParam = param;
    },
    changeTab(tab) {
      const { name } = tab;
      this.searchParams.sstatus = name;
      this.pageNum = 1;
      this.querartmesList();
    },
    current_page_change(param) {
      this.pageNum = param;
      this.querartmesList();
    },
    handleSizeChange(param) {
      this.pageNum = 1;
      this.pageSize = param;
      this.querartmesList();
    },
  },
};
</script>

<style scoped lang="scss">
  .manage {
    .relative {
      position: relative;
    }

    .release_btn {
      position: absolute;
      right: 23px;
      top: 17px;
      z-index: 33;
    }
  }

</style>
<style lang="scss">
  .manage{
    .el-table .el-table__cell .cell{
      line-height: 1.4em !important;
    }
    .el-table .el-table__cell{
      padding: 0px 5px !important;
    }
  }
</style>
