<template>
  <el-dialog
    custom-class="dialog-custom edposter"
    :append-to-body="true"
    :close-on-click-modal="false"
    title="发布海报"
    :visible.sync="visible"
    :before-close="handleClose"
  >
    <div class="poster-box">
      <el-form
        ref="form"
        :model="posterModal"
        :rules="rules"
        label-width="80px"
      >
        <el-form-item
          label="海报标题"
          prop="stitle"
        >
          <el-input
            v-model="posterModal.stitle"
            placeholder="请输入标题"
            maxlength="50"
            show-word-limit
          />
        </el-form-item>
        <el-form-item
          label="图片"
          prop="simg_url"
          class="lin-15"
        >
          <span slot="label"><span class="redreq">*</span>图片</span>
          <el-upload
            action
            :http-request="Upload"
            accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PBG"
            name="poster"
            :class="posterModal.simg_url ? 'hiddenborder upload-two' : 'upload-two'"
            drag
            :before-upload="beforeUpload"
            :show-file-list="false"
          >
            <div
              v-if="!posterModal.simg_url"
              class="uimg-conent"
            >
              <div class="l-h-20">
                <i class="iconfont shangchuantupian upicon" />
              </div>
              <div class="i-font">
                上传图片
              </div>
            </div>
            <div
              v-else
              class="up_img"
            >
              <img :src="posterModal.simg_url">
              <span class="baclk_back" />
              <div class="icon_pos">
                <div class="m-b-7">
                  <i class="iconfont xiugai white" />
                </div>
                <div>修改图片</div>
              </div>
            </div>
          </el-upload>
          <div
            v-if="uploadLoading"
            class="cover_progess"
          >
            <el-progress
              type="circle"
              :percentage="coProgress"
            />
          </div>
        </el-form-item>
        <el-form-item
          label="显示状态"
          prop="sshow_status"
        >
          <el-select
            v-model="posterModal.sshow_status"
          >
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="链接地址"
          prop="sjump_url"
        >
          <el-input
            v-model="posterModal.sjump_url"
            placeholder=""
            maxlength="500"
          />
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="footer"
      >
        <z-button
          type="primary"
          margin="0 20px"
          @click="push"
        >
          发布
        </z-button>
        <z-button
          type="info"
          margin="0 20px"
          @click="handleClose"
        >
          取消
        </z-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { getoss } from '../../api/video';
  import { client } from '../../api/ali-oss';
  import { addPoster, updatePoster } from "../../api/poster"
  export default {
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
      currentParam: {
        type: Object,
        default() { return {}; },
      },
    },
    computed: {
      ...mapGetters(['brandInfo']),
    },
    data() {
      return {
        proAut: {},
        statusList: [
          { value: "0", label: "即将开始" },
          { value: "1", label: "进行中" },
          { value: "2", label: "已结束" }
        ],
        rules: {
          stitle: [
            { required: true, message: '标题不能为空', trigger: 'change' }
          ],
          simg_url: [{
            validator(rule, value, callback) {
              if (!value) callback(false);
              else callback();
            },
            message: '图片不能为空',
            trigger: 'change',
          }],
          sshow_status: [
            { required: true, message: '显示状态不能为空', trigger: 'change' }
          ],
        },
        uploadLoading: false,
        progress: 0,
        coProgress: 0,
        showcove: false,
        modfiyshow: false,
        nosaveshow: false,
        posterModal: {
          sposter_id: '',
          stitle: '',
          simg_url: '',
          sshow_status: '',
          sjump_url: ''
        },
      }
    },
    mounted() {
      this.getproauthor();
      let posterModal = {
        sposter_id: '',
        stitle: '',
        simg_url: '',
        sshow_status: '',
        sjump_url: ''
      }
      if(this.currentParam && Object.keys(this.currentParam).length > 0) {
        posterModal = {
          sposter_id: this.currentParam.sposter_id ? this.currentParam.sposter_id: '',
          stitle: this.currentParam.stitle ? this.currentParam.stitle : '',
          simg_url: this.currentParam.simg_url ? this.currentParam.simg_url : '',
          sshow_status: this.currentParam.sshow_status ? this.currentParam.sshow_status : '',
          sjump_url: this.currentParam.sjump_url ? this.currentParam.sjump_url : ''
        }
      }
      this.posterModal = posterModal
    },
    methods: {
      handleClose() {
        this.$emit('update:visible', false);
      },
      getproauthor() {
        getoss().then((res) => {
          if (res.code === 0) {
            this.proAut = res.data;
          }
          // console.log(this.proAut);
        }).catch((e) => this.$message.error('系统异常，请联系管理员'));
      },
      // 上传之前
      uploadVideoProcess(event, file, fileList) {
        // debugger;
        this.uploadLoading = true;
      },
      // 上传图片验证
      beforeUpload(file) {
        let isFormat = true
        if (file.type.indexOf("image/jpg") < 0 &&
          file.type.indexOf("image/jpeg") < 0 && 
          file.type.indexOf("image/png") < 0 && 
          file.type.indexOf("image/JPG") < 0 && 
          file.type.indexOf("image/JPEG") < 0 && 
          file.type.indexOf("image/PBG") < 0) {
          this.$message.error('请上传图片格式的文件!');
          return false
        }
        const isLt2M = file.size / 1024 / 1024 < 10;
        const limitStr = ['《', '》', '%', '#', '*', ';', '[', ']', '【', '】', '{', '}', '&', '$'];
        const bool = !limitStr.find((x) => file.name.indexOf(x) > -1);
        if (!isLt2M) {
          this.$message.error('上传图片大小不能超过 10MB!')
          return false
        }
        if (!bool) {
          this.$message.error('图片命名不得包含非法字符!')
          return false
        }
        return true;
      },
      Upload(file) {
        this.uploadLoading = true;
        let config = {};
        if (this.proAut) {
          config = {
            region: this.proAut.region, // 后台给
            accessKeyId: this.proAut.accessKeyId, // 后台给
            accessKeySecret: this.proAut.accessKeySecret, // 后台给
            bucket: this.proAut.bucket,
            stsToken: this.proAut.stsToken,
          };
        }
        const that = this;
        that.showcove = true;
        async function multipartUpload() {
          const temporary = file.file.name.lastIndexOf('.');
          const fileNameLength = file.file.name.length;
          const fileFormat = file.file.name.substring(
            temporary + 1,
            fileNameLength,
          );
          // let fileName = getFileNameUUID() + "." + fileFormat;
          const fileName = file.file.name;
          client(config)
            .multipartUpload(`poster/${fileName}`, file.file, {
              progress(p) {
                // p进度条的值
                console.log(p);
                // that.showProgress = true;
                that.coProgress = Math.floor(p * 100);
              },
            })
            .then((result) => {
              // 上传成功返回值，可针对项目需求写其他逻辑
              console.log('返回2', result.res.requestUrls[0]);
              // eslint-disable-next-line eqeqeq
              if (result.res.requestUrls[0].indexOf('?uploadId') != -1) {
                const imgurl = that.getCaption(result.res.requestUrls[0], '?uploadId');
                that.posterModal.simg_url = imgurl;
              } else {
                that.posterModal.simg_url = result.res.requestUrls[0];
              }
              that.uploadLoading = false;
            })
            .catch((err) => {
              that.$message.error('上传失败，请联系管理员');
              console.log('err:', err);
            });
        }
        multipartUpload();
      },
      // 截取指定字符后的内容
      getCaption(url, parameter) {
        const index = url.indexOf(parameter);
        const url2 = url.substring(0, index);
        return url2;
      },
      push() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            // 新建或更新
            if (this.posterModal.sposter_id) {
              this.updatePosterFun();
            } else {
              this.newPoster();
            }
          }
        });
      },
      newPoster() {
        const param = {
          nbrand_id: this.brandInfo.nbrandId,
          stitle: this.posterModal.stitle,
          simg_url: this.posterModal.simg_url,
          sshow_status: this.posterModal.sshow_status,
          sjump_url: this.posterModal.sjump_url
        };
        addPoster(param).then((res) => {
          const {code} = res
          if (code === 0) {
            this.$message.success('发布成功');
            this.$emit('refreshList');
          } else {
            this.$message.error('发布失败，请联系管理员');
          }
        })
      },
      updatePosterFun() {
        const param = {
          nbrand_id: this.brandInfo.nbrandId,
          sposter_id: this.posterModal.sposter_id,
          stitle: this.posterModal.stitle,
          simg_url: this.posterModal.simg_url,
          sshow_status: this.posterModal.sshow_status,
          sjump_url: this.posterModal.sjump_url
        };
        updatePoster(param).then((res) => {
          const {code} = res
          if (code === 0) {
            this.$message.success('修改成功');
            this.$emit('refreshList');
          } else {
            this.$message.error('修改失败，请联系管理员');
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .edposter {
    ::v-deep {
      .el-dialog__body {
        padding: 20px;
      }
      .progress {
        width: 360px;
      }
    }
  }
  .footer {
    text-align: center;
  }
  ::v-deep .edposter {
    .upload-two{
      .el-upload-dragger{
        width: 142px;
        height: 80px;
      }
    }
    .hiddenborder{
      .el-upload-dragger{
       border: none!important;
      }
    }
    .upicon{
      font-size: 26px;
      top: 2px;
      position: relative;
      margin-right: 6px;
      color: #19B0B0;
    }
    .up_class{
      padding:55px 0px 0px;
      position: relative;
      .up_title{
        font-size: 16px;
        font-weight: bold;
        color: #19B0B0;
        line-height: 17px;
      }
      .up_con{
        margin-top: 12px;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
        text-align: left;
        padding-left: 147px;
      }
    }
    .uimg-conent{
      position: relative;
      padding: 22px 0;
      box-sizing: border-box;
      .l-h-20{
        line-height: 20px;
      }
      .i-font{
        font-size: 12px;
        font-weight: 400;
        color: #19B0B0;
        line-height: 11px;
        margin-top: 5px;
      }
    }
    .up_img{
      width: 142px;
      height: 80px;
      img{
        width: 100%;
        height: 100%;
      }
      .baclk_back{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, .6);
      }
      .icon_pos{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #FFFFFF;
        .white{
          color: #FFFFFF;
          font-size: 22px;
          margin-bottom: 7px;
        }
      }
    }
    .cover_progess{
      position: absolute;
      top: 0;
      left: 8%;
      .el-progress-circle{
        width: 80px!important;
        height: 80px!important;
      }
    }
  }
  .redreq{
    color: #F56C6C;
    margin-right: 4px;
  }
</style>