import request from '@/api/axios';

// 文章列表查询
export function managerList(query) {
  return request({
    url: '/param/paper/l_list',
    method: 'post',
    data: query,
  });
}

// 添加文章
export function addmanager(query) {
  return request({
    url: '/param/paper/l_addOrModi',
    method: 'post',
    data: query,
  });
}

// 上下架文章
export function offart(query) {
  return request({
    url: '/param/paper/l_updateStatus',
    method: 'post',
    data: query,
  });
}
